import React from 'react';
import Image, { StaticImageData } from 'next/image';
import useTranslation from 'next-translate/useTranslation';

import classes from './index.module.scss';

import { H3, P } from '@/components/common/Typography';
import { gapInPx } from '@/styles/theme';
import SectionLabel from '@/components/common/SectionLabel';
import { I18nLink } from '@/components/I18nLink';
import { ArrowRight } from '@/components/ArrowRight';

interface Props {
  icon: StaticImageData;
  iconAlt: string;
  badge: string;
  title: string;
  description: string;
  anchor?: string;
  isAnchorInternal?: boolean;
}

export const Card: React.FunctionComponent<Props> = ({
  title,
  description,
  icon,
  iconAlt,
  anchor,
  badge,
  isAnchorInternal = false,
}) => {
  const { t } = useTranslation('home');

  const CardWrapperElement = (
    <a
      className={classes.cardWrapper}
      href={anchor}
      target={isAnchorInternal ? undefined : '_blank'}
      rel={isAnchorInternal ? undefined : 'noopener'}
    >
      {badge && (
        <SectionLabel
          className={classes.badge}
          labelText={t(badge)}
          offset={{
            value: gapInPx(4),
          }}
        />
      )}
      <div className={classes.cardInnerWrapper}>
        <div className={classes.icon}>
          <Image src={icon} alt={iconAlt} />
        </div>
        <H3 className={classes.title}>{t(title)}</H3>
        <P className={classes.description}>{t(description)}</P>
      </div>
      {anchor && (
        <div className={classes.arrow}>
          <ArrowRight fill="blue" />
        </div>
      )}
    </a>
  );

  return isAnchorInternal && anchor ? (
    <I18nLink href={anchor}>{CardWrapperElement}</I18nLink>
  ) : (
    CardWrapperElement
  );
};
