
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { useCallback, useContext, useEffect, useMemo, useRef, useState, } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useEvent } from 'react-use';
import debounce from 'lodash/debounce';
import { StaticImageData } from 'next/image';
import { useDrag } from '@use-gesture/react';
import { animated, useSpring } from 'react-spring';
import clsx from 'clsx';
import classes from './index.module.scss';
import { Card } from './components/Card';
import IsoIcon from './assets/iso.svg';
import StabilityIcon from './assets/stability.svg';
import GlobalReachIcon from './assets/globalReach.svg';
import GdprIcon from './assets/gdpr.svg';
import ImplementationIcon from './assets/implementation.svg';
import SpecialistsIcon from './assets/specialists.svg';
import AdditionalToolsIcon from './assets/additionalTools.svg';
import Header from '@/components/common/Header';
import ConfigsContext from '@/contexts/configs';
import SlideButton from '@/components/common/Button/CarouselButton';
import ArrowLeftSvg from '@/components/Carousel/ArrowLeft';
import ArrowRightSvg from '@/components/Carousel/ArrowRight';
import { pushToDataLayer } from '@/helpers/googleTagManager';
const NO_DRAG_OFFSET = 0;
const DRAG_DISTANCE_CONSIDERED_LONG_ENOUGH_TO_CHANGE_SLIDE = 100;
const iconMap: {
    [key: string]: {
        alt: string;
        src: StaticImageData;
    };
} = {
    dataProtection: {
        alt: 'ISO icon - SMSAPI',
        src: IsoIcon,
    },
    stability: {
        alt: 'Monitoring icon - SMSAPI',
        src: StabilityIcon,
    },
    globalReach: {
        alt: 'Global icon - SMSAPI',
        src: GlobalReachIcon,
    },
    additionalTools: {
        alt: 'Features icon - SMSAPI',
        src: AdditionalToolsIcon,
    },
    gdpr: {
        alt: 'GDPR icon - SMSAPI',
        src: GdprIcon,
    },
    implementation: {
        alt: 'Education icon - SMSAPI',
        src: ImplementationIcon,
    },
    specialists: {
        alt: 'Knowledge icon - SMSAPI',
        src: SpecialistsIcon,
    },
};
const WhatMakesUsDifferent: React.FunctionComponent<React.PropsWithChildren> = () => {
    const { t } = useTranslation('home');
    const { distinctiveFeatures } = useContext(ConfigsContext);
    const [currentSliderPosition, setCurrentSliderPosition] = useState(0);
    const [featuresWidth, setFeaturesWidth] = useState(0);
    const featuresWrapperRef = useRef<HTMLDivElement>(null);
    const featuresInnerWrapperRef = useRef<HTMLDivElement>(null);
    const [howManyFeaturesFitInWrapper, setHowManyFeaturesFitInWrapper] = useState(1);
    const featureWidth = useMemo((): number => {
        return Math.round(featuresWidth / distinctiveFeatures.length);
    }, [distinctiveFeatures, featuresWidth]);
    const getHowManyFeaturesFitInWrapper = useCallback(() => {
        if (featuresWrapperRef.current) {
            if (featureWidth === 0)
                return;
            setHowManyFeaturesFitInWrapper(Math.floor(featuresWrapperRef?.current?.clientWidth / featureWidth));
        }
    }, [featureWidth]);
    useEffect(() => {
        if (featuresInnerWrapperRef.current) {
            getHowManyFeaturesFitInWrapper();
            setFeaturesWidth(featuresInnerWrapperRef.current.scrollWidth || 0);
        }
    }, [getHowManyFeaturesFitInWrapper]);
    useEvent('resize', debounce(() => {
        getHowManyFeaturesFitInWrapper();
        setCurrentSliderPosition(currentSliderPosition <= maxSlidePosition
            ? currentSliderPosition
            : maxSlidePosition);
    }, 250));
    const minSlidePosition = 0;
    const maxSlidePosition = featureWidth * (distinctiveFeatures.length - howManyFeaturesFitInWrapper);
    const canMoveToNextSlide = currentSliderPosition + featureWidth <= maxSlidePosition;
    const canMoveToPrevSlide = currentSliderPosition > 0;
    const [featuresSlideSpring, featuresSlideSpringApi] = useSpring(() => ({
        to: {
            transform: `translate3d(calc(${currentSliderPosition}px * (-1) + ${NO_DRAG_OFFSET}px), 0, 0)`,
        },
    }), [currentSliderPosition]);
    const moveToNextSlide = () => {
        if (!canMoveToNextSlide)
            return;
        setCurrentSliderPosition((prevSlide) => prevSlide + featureWidth);
    };
    const moveToPrevSlide = () => {
        if (!canMoveToPrevSlide)
            return;
        setCurrentSliderPosition((prevSlide) => prevSlide - featureWidth);
    };
    const drag = useDrag((gestureState) => {
        const [movementX] = gestureState.movement;
        const translateOffset = 48;
        const translateBoundries = {
            left: 0 - translateOffset,
            right: maxSlidePosition + translateOffset,
        };
        if (gestureState.down) {
            if (currentSliderPosition - movementX <= translateBoundries.left ||
                currentSliderPosition - movementX >= translateBoundries.right)
                return;
            featuresSlideSpringApi.set({
                transform: `translate3d(calc(${currentSliderPosition}px * (-1) + ${movementX}px), 0, 0)`,
            });
        }
        else {
            featuresSlideSpringApi.start({
                to: {
                    transform: `translate3d(calc(${currentSliderPosition}px * (-1) + ${NO_DRAG_OFFSET}px), 0, 0)`,
                },
            });
        }
        const [swipeX] = gestureState.swipe;
        const hasSwipedLeft = swipeX < 0;
        const hasSwipedRight = swipeX > 0;
        const hasDraggedLeft = movementX < -DRAG_DISTANCE_CONSIDERED_LONG_ENOUGH_TO_CHANGE_SLIDE &&
            !gestureState.down;
        const hasDraggedRight = movementX > DRAG_DISTANCE_CONSIDERED_LONG_ENOUGH_TO_CHANGE_SLIDE &&
            !gestureState.down;
        const howManySlidesHasBeenSwipe = Math.abs(Math.round(movementX / featureWidth));
        if (hasSwipedLeft || hasDraggedLeft) {
            pushToDataLayer('custom_click', {
                clickedElement: 'swipe-left-distinctive-features',
            });
            const newPosition = currentSliderPosition + howManySlidesHasBeenSwipe * featureWidth;
            if (newPosition >= maxSlidePosition) {
                setCurrentSliderPosition(maxSlidePosition);
            }
            else
                setCurrentSliderPosition(newPosition);
        }
        if (hasSwipedRight || hasDraggedRight) {
            if (currentSliderPosition <= 0)
                return;
            pushToDataLayer('custom_click', {
                clickedElement: 'swipe-right-distinctive-features',
            });
            const newPosition = currentSliderPosition - howManySlidesHasBeenSwipe * featureWidth;
            if (newPosition <= minSlidePosition) {
                setCurrentSliderPosition(minSlidePosition);
            }
            else
                setCurrentSliderPosition(newPosition);
        }
    }, {
        filterTaps: true,
        preventDefault: true,
    });
    return (<section className={classes.section}>
      <Header className={classes.header} text={t('What is our distinctive feature?')} color="darkBlue"/>
      <div className={classes.sliderButtons}>
        <SlideButton className={clsx(classes.sliderButton, !canMoveToPrevSlide && classes.sliderButton_disabled)} onClick={() => {
            pushToDataLayer('custom_click', {
                clickedElement: 'prev-arrow-distinctive-features',
            });
            moveToPrevSlide();
        }} disabled={!canMoveToPrevSlide}>
          <ArrowLeftSvg />
        </SlideButton>
        <SlideButton className={clsx(classes.sliderButton, !canMoveToNextSlide && classes.sliderButton_disabled)} onClick={() => {
            pushToDataLayer('custom_click', {
                clickedElement: 'next-arrow-distinctive-features',
            });
            moveToNextSlide();
        }} disabled={!canMoveToNextSlide}>
          <ArrowRightSvg />
        </SlideButton>
      </div>
      <div className={classes.features} ref={featuresWrapperRef} {...drag()}>
        <animated.div className={classes.featuresInnerWrapper} ref={featuresInnerWrapperRef} style={featuresSlideSpring}>
          {distinctiveFeatures.map((distinctiveFeature) => (<Card key={distinctiveFeature.title} badge={distinctiveFeature.badge} title={distinctiveFeature.title} description={distinctiveFeature.description} icon={iconMap[distinctiveFeature.icon].src} iconAlt={iconMap[distinctiveFeature.icon].alt} anchor={distinctiveFeature.pageName
                ? `/${distinctiveFeature.pageName}`
                : distinctiveFeature.externalUrl} isAnchorInternal={!!distinctiveFeature.pageName}/>))}
        </animated.div>
      </div>
    </section>);
};
export default WhatMakesUsDifferent;

    async function __Next_Translate__getStaticProps__1910ce4137c__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//Home/components/WhatMakesUsDifferent/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1910ce4137c__ as getStaticProps }
  